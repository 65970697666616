import { graphql, Link, useStaticQuery } from 'gatsby';
import React, { useEffect, useState } from 'react';
import Footer from '../components/footer/Footer';
import HeaderCommon from '../components/header/HeaderCommon';
import Layout from '../components/layout';
import MediaMentionItem from '../components/media-mention/MediaMentionItem';
import Seo from '../components/seo';
import BreadcrumbSection from '../elements/BreadcrumbSection';

const MediaMention = () => {
  const { allStrapiMediaMention } = useStaticQuery(graphql`
    query {
      allStrapiMediaMention(sort: { fields: Date, order: DESC }) {
        edges {
          node {
            id
            Slug
            Cover {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 500, height: 325)
                }
              }
            }
            Date
            Title
          }
        }
      }
    }
  `);

  // Array of all Midea Mentions
  const allStrapiMediaMentions = allStrapiMediaMention.edges;

  // State for the list
  const [list, setList] = useState([...allStrapiMediaMentions.slice(0, 12)]);

  // State to trigger oad more
  const [loadMore, setLoadMore] = useState(false);

  // State of whether there is more to load
  const [hasMore, setHasMore] = useState(allStrapiMediaMentions.length > 12);

  // Load more button click
  const handleLoadMore = () => {
    setLoadMore(true);
  };

  // Handle loading more articles
  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = list.length;
      const isMore = currentLength < allStrapiMediaMentions.length;
      const nextResults = isMore
        ? allStrapiMediaMentions.slice(currentLength, currentLength + 10)
        : [];
      setList([...list, ...nextResults]);
      setLoadMore(false);
    }
  }, [loadMore, hasMore]);

  // Check if there is more
  useEffect(() => {
    const isMore = list.length < allStrapiMediaMentions.length;
    setHasMore(isMore);
  }, [list]);

  return (
    <Layout>
      <Seo
        title="Media Mentions | banco in the news"
        description="banco latest news, press releases and features. Write to media@banco.com.sg for any news and press opportunities"
      />
      <main className="main-wrapper">
        <HeaderCommon />
        <BreadcrumbSection
          title="Media mentions"
          paragraph={
            <span style={{ display: 'inline-block', width: '100%', textAlign: 'center' }}>
              For press or media opportunities, please send us an email at{' '}
              <Link to="mailto:media@banco.com.sg">media@banco.com.sg</Link>.
            </span>
          }
        />
        <div className="section section-media-list">
          <div className="container">
            <div className="row row-8">
              {list.map((data) => (
                <div className="col-xl-3 col-lg-4 col-md-6 pb--70" key={data.node.id}>
                  <MediaMentionItem data={data.node} />
                </div>
              ))}
            </div>
            {hasMore && (
              <div className="row row-8 justify-content-md-center">
                <div className="col-lg-2 col-md-4 col-sm-6">
                  <button className="banco-btn btn-borderd btn-fluid" onClick={handleLoadMore}>
                    View more
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        <Footer />
      </main>
    </Layout>
  );
};

export default MediaMention;
